export function getFutureDates(inputDate: Date, dayOfWeek: number, weeks: number) {
  const dates = [];

  for (let i = 1; i < weeks * 7; i++) {
    const currentDate = new Date(inputDate);
    currentDate.setDate(inputDate.getDate() + i);

    if (currentDate.getDay() === dayOfWeek && currentDate !== new Date(inputDate)) {
      const formattedDate = formatDate(currentDate);
      dates.push(formattedDate);
    }
  }

  return dates;
}

export function formatDate(date: Date) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const day = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

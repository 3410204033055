import { PaginationMeta, Meta, CreateEventRequest, Event, EventDateWithoutEvent } from "@/types";
import { axiosInstance } from "../clients";

async function listEvents(page: string, role?: string): Promise<{ data: { events: Event[] }; meta: PaginationMeta }> {
  const queryParams = role ? `page=${page}&role=${role}` : `page=${page}`;
  return (await axiosInstance.get(`/v1/events?${queryParams}`, { withCredentials: true })).data;
}

async function listUserEvents(page: string, id: string): Promise<{ data: { events: Event[] }; meta: PaginationMeta }> {
  const queryParams = `page=${page}&user_id=${id}`;
  return (await axiosInstance.get(`/v1/events?${queryParams}`, { withCredentials: true })).data;
}

async function listUserBookings(page: string, id: string): Promise<{ data: { events: Event[] }; meta: PaginationMeta }> {
  const queryParams = `page=${page}&user_id=${id}`;
  return (await axiosInstance.get(`/v1/events?${queryParams}`, { withCredentials: true })).data;
}

async function getEvent(id: string): Promise<{ data: { event: Event }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/events/${id}`, { withCredentials: true })).data;
}

async function createEvent(data: CreateEventRequest): Promise<{ data: { event: Event; eventDates: EventDateWithoutEvent[] }; meta: Meta }> {
  return (await axiosInstance.post("/v1/events", data, { withCredentials: true })).data;
}

async function updateEvent(id: string, data: any): Promise<{ data: { event: Event }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/events/${id}`, data, { withCredentials: true })).data;
}

async function deleteEvent(id: string): Promise<{ data: { deleted: boolean }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/events/${id}`, { withCredentials: true })).data;
}

async function updateEventVisibility(id: string, data: any): Promise<{ data: { event: Event }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/events/${id}/visibility`, data, { withCredentials: true })).data;
}

async function updateEventStatus(id: string, data: any): Promise<{ data: { event: Event }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/events/${id}/status`, data, { withCredentials: true })).data;
}

async function markInvoiceAsIssued(id: number): Promise<{ data: { event: Event }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/events/${id}/invoice/issued`, { withCredentials: true })).data;
}

async function markInvoiceAsPaid(id: number): Promise<{ data: { event: Event }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/events/${id}/invoice/paid`, { withCredentials: true })).data;
}

export default {
  listEvents,
  listUserEvents,
  listUserBookings,
  getEvent,
  createEvent,
  updateEvent,
  deleteEvent,
  updateEventVisibility,
  updateEventStatus,
  markInvoiceAsIssued,
  markInvoiceAsPaid,
};

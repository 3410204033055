import { Transaction, PaginationMeta } from "@/types";
import { axiosInstance } from "../clients";

async function listSubscriptions(data: { page: string | number; method?: string; status?: string }): Promise<{ meta: PaginationMeta; data: { subscriptions: Transaction[] } }> {
  return (await axiosInstance.get(`/v1/subscriptions`, { params: data })).data;
}

async function getSubscription(id: string): Promise<{ meta: PaginationMeta; data: { subscription: Transaction } }> {
  return (await axiosInstance.get(`/v1/subscriptions/invoice/${id}`)).data;
}

async function markSubscriptionInvoiceAsPaid(id: string): Promise<{ meta: PaginationMeta; data: { transaction: Transaction } }> {
  return (await axiosInstance.post(`/v1/subscriptions/invoice/paid/${id}`)).data;
}

async function markSubscriptionInvoiceIssued(id: string): Promise<{ meta: PaginationMeta; data: { transaction: Transaction } }> {
  return (await axiosInstance.post(`/v1/subscriptions/invoice/issued/${id}`)).data;
}

export default { listSubscriptions, markSubscriptionInvoiceAsPaid, markSubscriptionInvoiceIssued, getSubscription };

import { Club, CreateStudentNoteRequest, Student, StudentNote, User } from "@/types";
import { axiosInstance } from "../clients";

/** Club Notes */
async function listStudentNotes(club_id: Club["id"], student_id: Student["id"]) {
  return (await axiosInstance.get(`/v1/clubs/${club_id}/students/${student_id}/notes`, { withCredentials: true })).data;
}

async function createStudentNote(club_id: Club["id"], student_id: Student["id"], data: CreateStudentNoteRequest) {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/students/${student_id}/notes`, data, { withCredentials: true })).data;
}

async function updateStudentNote(club_id: Club["id"], student_id: Student["id"], id: StudentNote["id"], data: CreateStudentNoteRequest) {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/students/${student_id}/notes/${id}`, data, { withCredentials: true })).data;
}

async function deleteStudentNote(club_id: Club["id"], student_id: Student["id"], id: StudentNote["id"]) {
  return (await axiosInstance.delete(`/v1/clubs/${club_id}/students/${student_id}/notes/${id}`, { withCredentials: true })).data;
}

async function listStudentsByParent(parent_id: User["id"]): Promise<{ meta: { status: 200 }; data: { students: Student[] } }> {
  return (await axiosInstance.get(`/v1/parent/${parent_id}/students`, { withCredentials: true })).data;
}

async function getStudentByParent(parent_id: User["id"], student_id: Student["id"]): Promise<{ meta: { status: 200 }; data: { student: Student } }> {
  return (await axiosInstance.get(`/v1/parent/${parent_id}/students/${student_id}`, { withCredentials: true })).data;
}

async function updateStudentByParent(parent_id: User["id"], student_id: Student["id"], data: any): Promise<{ meta: { status: 200 }; data: { student: Student } }> {
  return (await axiosInstance.post(`/v1/parent/${parent_id}/students/${student_id}`, data, { withCredentials: true })).data;
}

async function deleteStudentByParent(parent_id: User["id"], student_id: Student["id"]): Promise<{ meta: { status: 200 }; data: { student: Student } }> {
  return (await axiosInstance.delete(`/v1/parent/${parent_id}/students/${student_id}`, { withCredentials: true })).data;
}

async function listParentStudentClubs(parent_id: User["id"]): Promise<{ meta: { status: 200 }; data: { clubs: Club[] } }> {
  return (await axiosInstance.get(`/v1/parent/${parent_id}/clubs`, { withCredentials: true })).data;
}

async function listParentStudentNotes(parent_id: User["id"], student_id: Student["id"]): Promise<{ meta: { status: 200 }; data: { notes: StudentNote[] } }> {
  return (await axiosInstance.get(`/v1/parent/${parent_id}/students/${student_id}/notes`, { withCredentials: true })).data;
}

export default {
  listStudentNotes,
  createStudentNote,
  updateStudentNote,
  deleteStudentNote,
  listStudentsByParent,
  getStudentByParent,
  updateStudentByParent,
  listParentStudentClubs,
  deleteStudentByParent,
  listParentStudentNotes,
};

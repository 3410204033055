import { Organisation, License, PaginationMeta } from "@/types";
import { axiosInstance } from "../clients";

async function listLicenses(data: { page: string | number; method?: string; status?: string }): Promise<{ data: { meta: PaginationMeta; licenses: License[] } }> {
  return (await axiosInstance.get(`/v1/licenses`, { params: data })).data;
}

async function listLicensesByOrg(organisation_id: Organisation["id"]): Promise<{ data: { licenses: License[] } }> {
  return (await axiosInstance.get(`/v1/licenses/${organisation_id}`)).data;
}

async function getLicenseByLevel(organisation_id: Organisation["id"], level: string): Promise<{ data: { license: License } }> {
  return (await axiosInstance.get(`/v1/licenses/${organisation_id}/level/${encodeURI(level)}`)).data;
}

async function getLicenseById(organisation_id: Organisation["id"], id: License["id"]): Promise<{ data: { license: License } }> {
  return (await axiosInstance.get(`/v1/licenses/${organisation_id}/id/${id}`)).data;
}

async function toggleLicenseInvoiceIssued(organisation_id: Organisation["id"], id: License["id"]): Promise<{ data: { license: License } }> {
  return (await axiosInstance.post(`/v1/licenses/${organisation_id}/invoice/${id}`)).data;
}

async function markLicenseInvoiceAsPaid(organisation_id: Organisation["id"], id: License["id"]): Promise<{ data: { license: License } }> {
  return (await axiosInstance.post(`/v1/licenses/${organisation_id}/paid/${id}`)).data;
}

export default { listLicenses, listLicensesByOrg, getLicenseByLevel, getLicenseById, toggleLicenseInvoiceIssued, markLicenseInvoiceAsPaid };

export default {
  "new_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Event"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
  "email_marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Marketing"])},
  "email_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Preferences"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
  "account_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Security"])},
  "add_a_new_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new User"])},
  "user_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Profile"])},
  "user_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Groups"])},
  "user_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
  "course_prerequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Prerequisites"])},
  "course_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Templates"])},
  "add_a_new_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Template"])},
  "edit_course_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Course Template"])},
  "training_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Events"])},
  "add_a_new_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Event"])},
  "training_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Event"])},
  "user_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Order"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
  "upload_Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Resource"])},
  "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
  "edit_Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Resource"])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts"])},
  "add_a_new_Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Discount"])},
  "edit_Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Discount"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
  "brick_clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Clubs"])},
  "view_club_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view club participant"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["summary"])},
  "course_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course description"])},
  "registered_attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registered attendees"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing"])},
  "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individual"])},
  "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelled"])},
  "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheduled"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draft"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completed"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmed"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["published"])},
  "no_registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no registrations"])},
  "minimum_not_met": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum not met"])},
  "in review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in review"])},
  "unpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unpublished"])},
  "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event name"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actions"])},
  "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["training"])},
  "trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trainer"])},
  "course_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course template"])},
  "course_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course level"])},
  "course_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course type"])},
  "delivery_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery method"])},
  "delivery_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery date"])},
  "available_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available countries"])},
  "date_and_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date and time"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])},
  "training_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["training link"])},
  "event_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event owner"])},
  "presenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["presenters"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])},
  "payment_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment link"])},
  "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platform name for payments"])},
  "attendance_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attendace limit"])},
  "maximum_attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maximum number of participants"])},
  "minimum_attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum number of participants"])},
  "waiting_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waiting list"])},
  "go_live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date to publish this event"])},
  "registration_cut_off_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration cut off date"])},
  "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start time"])},
  "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end time"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["region"])},
  "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation"])},
  "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment method"])},
  "payment_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment platform"])},
  "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])},
  "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complete"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view"])},
  "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you, your course has been submitted for review. You will be sent an invoice for the course licence fee within the next 5 working days. Your course will be approved by the Play Included team once the licence fee payment has been received."])},
  "status_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This event is"])},
  "course_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course feedback"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rating"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedback"])},
  "welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back!"])},
  "your_training_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Training Events"])},
  "submit_event_for_approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Event for Approval"])},
  "edit_training_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Training Event"])},
  "save_training_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Training Event"])},
  "confirm_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Event"])},
  "publish_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Event Now"])},
  "unpublish_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpublish Event"])},
  "cancel_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Event"])},
  "change_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Price"])},
  "add_a_presenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a presenter"])},
  "add_another_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another date"])},
  "select_available_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Available Countries"])},
  "make_training_event_go_live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Training Event for Live"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
  "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete account"])},
  "change_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change your password"])},
  "add_session_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Session Note"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "add_existing_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add existing participant"])},
  "save_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save participant"])},
  "edit_brick_club_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit brick club participant"])},
  "delete_brick_club_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete brick club participant"])},
  "add_new_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add new participant"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])},
  "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email address"])},
  "email_address_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing this will require you to login again"])},
  "your_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your roles"])},
  "your_roles_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter each job on a new line"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bio"])},
  "facilitator_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facilitator settings"])},
  "trainer_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trainer settings"])},
  "hide_facilitator_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hide facilitator profile"])},
  "facilitator_public_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This profile will be viewable on the website as a facilitator. If you do not want this to appear on the website please toggle this setting off"])},
  "works_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with"])},
  "hide_trainer_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hide trainer profile"])},
  "trainer_public_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This profile will be viewable on the website as a trainer. If you do not want this to appear on the website please toggle this setting off"])},
  "language_and_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language and region"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
  "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timezone"])},
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])},
  "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promotion"])},
  "starts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starts"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expires"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
  "limit_per_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limit per customer"])},
  "limit_total_uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limit total uses"])},
  "starts_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start at"])},
  "expires_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expires at"])},
  "specific_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specific user"])},
  "specific_organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specific organisation"])},
  "apply_to_courses_tagged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apply to courses tagged"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
  "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["venue"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setting"])},
  "first_club_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first club date"])},
  "last_club_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last club date"])},
  "participants_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of participants"])},
  "places_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["places available"])},
  "session_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session notes"])},
  "age_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["age range"])},
  "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["note"])},
  "initials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["initials"])},
  "background_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["background notes"])},
  "brick_club_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brick club notes"])},
  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "course_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger at the end of"])},
  "last_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last modified"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
  "facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitator"])},
  "date_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Completed"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created"])},
  "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updated"])},
  "training_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Feedback"])},
  "submit_for_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submit for review"])},
  "contact_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact email"])},
  "save_brick_club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save brick club"])},
  "edit_brick_club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit brick club"])},
  "mobile_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile number"])},
  "for_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For"])},
  "survey_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["survey recipient/s"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expiration"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["level"])},
  "payment_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment type"])},
  "auto_renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auto renew"])},
  "child_outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["child outcomes"])},
  "club_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger every weeks"])},
  "show_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show parent"])},
  "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount code"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submit"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["files"])},
  "upload_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload files"])},
  "brick_club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club"])},
  "move_this_child_to_new_club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move this child to new Brick CLub"])},
  "organisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisations"])},
  "first_name_initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name initial"])},
  "family_name_initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Name Initial"])},
  "middle_name_initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle Name Initial(s) (optional)"])},
  "parent_carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent/carer"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "add_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add a"])},
  "edit_club_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Club Participant"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome"])},
  "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])},
  "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additional"])},
  "medication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medication"])},
  "thank_you_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank You"])},
  "brick_club_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club Members"])},
  "view_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Member"])},
  "Edit_Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Member"])},
  "scheduled_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled Events"])},
  "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booked"])},
  "order_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order No."])},
  "placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["placed"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
  "website_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website Data"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
  "my_children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Children"])},
  "my_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Child"])},
  "edit_child_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit Child Details"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["added"])},
  "edit_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Child"])},
  "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["capacity"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])},
  "invoice_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Issued"])},
  "add_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add dates"])},
  "add_multiple_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add multiple dates"])},
  "capacity_of_club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity of Club"])},
  "add_new_club_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new club member"])},
  "add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add another"])},
  "save_club_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save club member"])},
  "edit club member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit club member"])},
  "show_members_parent_carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show member's parent/carer"])},
  "brick_club_note_for_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club note for Member"])},
  "visible_to_member_parent_carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible to member’s parent/carer"])},
  "my_brick_clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Brick Clubs"])},
  "manage_brick_club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Brick Club"])},
  "add_note_for_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add note for member"])},
  "visible_to_members_parent_carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible to Member's Parent/Carer"])},
  "facilitator_session_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session checklist and notes"])},
  "please_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select"])},
  "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent"])},
  "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participant"])},
  "save_survey_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save_survey_details"])},
  "no_responses_to_this_survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No responses to this Survey"])},
  "view_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Session Attendance and Notes"])},
  "view_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View progress"])},
  "club_delete_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will delete all dates, session notes and student notes related to this Brick Club"])},
  "this_club_is_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This club is private"])},
  "this_club_is_public_and_not_accepting_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This club is public and is not accepting booking requests, your contact info is hidden"])},
  "this_club_is_public_and_accepting_bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This club is public and is accepting bookings, your contact info is shown"])},
  "please_select_visibility_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select level of visibility"])},
  "do_not_list_on_directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not list on Brick Club directory"])},
  "list_on_directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List on Brick Club directory"])},
  "list_on_directory_as_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List on Brick Club Directory as a private club"])},
  "smartsurvey_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session_checklist_survey_alert"])},
  "remove_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Participant"])},
  "move_club_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move member to another Brick Club"])},
  "date_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date added"])},
  "add_a_new_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Discount"])},
  "verifone_product_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifone Product Code"])},
  "merchant_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant Name"])},
  "percent_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Percentage"])},
  "edit_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit discount"])},
  "license_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Details"])},
  "issued by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued by"])},
  "attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attendee"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event"])},
  "subscription_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Details"])},
  "delegates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegates"])},
  "invoice_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invoice paid"])},
  "payment_gateway_customer_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway customer ID"])},
  "payment_gateway_order_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway Order ID"])},
  "event_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Date"])},
  "club_attendance_help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can track Brick Club members’ attendance at Brick Club"])},
  "total_no_of_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of sessions"])},
  "total_percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total %"])},
  "group_notes_for_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group notes for session"])},
  "session_checklist_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Checklist scores"])},
  "view_childs_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Child's Progress"])},
  "secondary_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Email"])},
  "secondary_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Phone Number"])},
  "event_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event ID"])},
  "get_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get help"])},
  "your_trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Trainers"])},
  "medication_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does your child have or take any medication that the Facilitator should know about? "])},
  "additional_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there anything else the Facilitator should know about your child to help them have a positive experience at Brick Club?"])},
  "progress_and_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress and Feedback"])},
  "member_session_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member session feedback"])},
  "goal_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal Tracking"])},
  "goal_tracking_helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can view and track the goals that your child is working on in Brick Club"])},
  "programme_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme Feedback"])},
  "member_session_feedback_helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can review feedback from your child in their Brick Club."])},
  "view_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View feedback"])},
  "view_outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View outcomes"])},
  "club_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club members"])},
  "club_members_helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can manage your Brick Club members and view their profiles"])},
  "session_checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Checklist"])},
  "member_attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Attendance"])},
  "member_progress_survey_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is time to evaluate your Brick Club."])},
  "session_checklist_survey_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Session checklist can help you track how true you are to the Brick-by-Brick® methodology"])},
  "default_brick_club_survey_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How did Brick Club go today?"])},
  "group_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group notes"])}
}
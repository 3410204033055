import auth from "./endpoints/auth";
import tags from "./endpoints/tags";
import misc from "./endpoints/misc";
import users from "./endpoints/users";
import brevo from "./endpoints/brevo";
import clubs from "./endpoints/clubs";
import events from "./endpoints/events";
import reports from "./endpoints/reports";
import actions from "./endpoints/actions";
import surveys from "./endpoints/surveys";
import license from "./endpoints/license";
import bookings from "./endpoints/bookings";
import students from "./endpoints/students";
import discounts from "./endpoints/discounts";
import templates from "./endpoints/templates";
import storyblok from "./endpoints/storyblok";
import resources from "./endpoints/resources";
import eventdates from "./endpoints/eventdates";
import subscriptions from "./endpoints/subscriptions";
import organisations from "./endpoints/organisations";

export default {
  auth,
  tags,
  misc,
  users,
  brevo,
  clubs,
  events,
  reports,
  actions,
  surveys,
  license,
  bookings,
  students,
  discounts,
  templates,
  storyblok,
  resources,
  eventdates,
  organisations,
  subscriptions
};

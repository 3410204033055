import { createRouter, createWebHistory } from "vue-router";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store";

/** Layouts */
import MainLayout from "../layouts/MainLayout.vue";

/** Views */
import IndexView from "@/views/IndexView.vue";
import LoginView from "@/views/LoginView.vue";
import LogoutView from "@/views/LogoutView.vue";
import SignupView from "@/views/SignupView.vue";
import SignupRedirect from "@/views/SignupRedirect.vue";

import RedirectView from "@/views/RedirectView.vue";

import DashboardView from "@/views/DashboardView.vue";
import AuthLayoutVue from "@/layouts/AuthLayout.vue";
import MyProfile from "@/views/user/MyProfile.vue";
import SubscriptionPage from "@/views/user/SubscriptionPage.vue";
import AccountSecurity from "@/views/user/AccountSecurity.vue";

import UsersList from "@/views/users/UsersList.vue";
import NewUser from "@/views/users/NewUser.vue";

import UserGroups from "@/views/users/UserGroups.vue";
import UserGroup from "@/views/users/UserGroup.vue";

import UserProfile from "@/views/users/UserProfile.vue";

import TemplatesIndex from "@/views/templates/TemplatesIndex.vue";
import TemplatesList from "@/views/templates/TemplatesList.vue";
import TemplatePage from "@/views/templates/TemplatePage.vue";
import NewTemplate from "@/views/templates/NewTemplate.vue";
import EditTemplatePage from "@/views/templates/EditTemplatePage.vue";

import EventsList from "@/views/events/EventsList.vue";
import EventPage from "@/views/events/EventPage.vue";
import NewEvent from "@/views/events/NewEvent.vue";
import EditEventPage from "@/views/events/EditEventPage.vue";
import OrderPage from "@/views/orders/OrderPage.vue";

import ResourcesList from "@/views/resources/ResourcesList.vue";
import NewResource from "@/views/resources/NewResource.vue";
import ResourcePage from "@/views/resources/ResourcePage.vue";
import EditResource from "@/views/resources/EditResource.vue";

import DiscountsList from "@/views/discounts/DiscountsList.vue";
import NewDiscount from "@/views/discounts/NewDiscount.vue";
import DiscountPage from "@/views/discounts/DiscountPage.vue";
import EditDiscount from "@/views/discounts/EditDiscount.vue";

import ErrorPage from "@/views/ErrorPage.vue";
import AcceptTerms from "@/views/orders/AcceptTerms.vue";

import CertificatesList from "@/views/CertificatesList.vue";

import ClubsList from "@/views/clubs/ClubsList.vue";
import NewClubVue from "@/views/clubs/NewClub.vue";
import EditClubPageVue from "@/views/clubs/EditClubPage.vue";
import ClubPageVue from "@/views/clubs/ClubPage.vue";

import NewStudent from "@/views/students/NewStudent.vue";
import StudentPage from "@/views/students/StudentPage.vue";
import EditStudent from "@/views/students/EditStudent.vue";

import SurveyIndex from "@/views/feedback/SurveyIndex.vue";
import SurveyPage from "@/views/feedback/SurveyPage.vue";

import ClubFeedbackPage from "@/views/child_outcomes/ClubFeedbackPage.vue";
import OrganisationList from "@/components/OrganisationList.vue";

/** List of Children // viewable by parents only */
import ClubMembers from "@/views/clubs/ClubMembers.vue";

import WebsiteData from "@/views/WebsiteData.vue";
import OrdersList from "@/views/orders/OrdersList.vue";
import EmailPreferenceVue from "@/views/user/EmailPreference.vue";
import LicenseView from "@/views/LicenseView.vue";

import SubscriptionOrderView from "@/views/SubscriptionOrderView.vue";
import ChildOutcomes from "@/views/child_outcomes/ChildOutcomes.vue";

const routes = [
  {
    path: "/",
    component: AuthLayoutVue,
    children: [
      {
        path: "/",
        name: "Welcome",
        component: IndexView,
      },
      {
        path: "/signup",
        name: "Signup",
        component: SignupView,
      },
      {
        path: "/redirect",
        name: "Redirect",
        component: RedirectView,
      },
      {
        path: "/signup/redirect",
        name: "Redirecting",
        component: SignupRedirect,
      },
      {
        path: "/login",
        name: "Login",
        component: LoginView,
        meta: {
          blockAuth: true,
        },
      },
    ],
  },
  {
    path: "/dashboard",
    name: "DashboardLayout",
    component: MainLayout,
    children: [
      { path: "/:pathMatch(.*)*", component: ErrorPage },
      {
        path: "/dashboard/",
        name: "Dashboard",

        component: DashboardView,
        meta: {
          name: "Dashboard",
          breadcrumbs: ["Dashboard"],
        },
      },
      {
        path: "/dashboard/websitedata/",
        name: "Website Data",
        component: WebsiteData,
        meta: {
          name: "Website Data",
          breadcrumbs: ["Dashboard", "Website Data"],
        },
      },
      {
        path: "/dashboard/profile",
        name: "My_Profile",

        component: MyProfile,
        meta: {
          name: "My Profile",
          breadcrumbs: ["Dashboard", "My_Profile"],
        },
      },
      {
        path: "/dashboard/profile/security",
        name: "Account_Security",

        component: AccountSecurity,
        meta: {
          name: "Account Security",
          breadcrumbs: ["Dashboard", "Account_Security"],
        },
      },
      {
        path: "/dashboard/profile/subscription",
        name: "Subscription",

        component: SubscriptionPage,
        meta: {
          name: "Subscription",
          breadcrumbs: ["Dashboard", "Subscription"],
        },
      },
      {
        path: "/dashboard/profile/email-preferences",
        name: "Email Preferences",
        component: EmailPreferenceVue,
        meta: {
          name: "Email Preferences",
          breadcrumbs: ["Dashboard", "Email Preferences"],
        },
      },
      {
        path: "/dashboard/organisations",
        name: "Organisations",
        component: OrganisationList,
        meta: {
          name: "Organisations",
          breadcrumbs: ["Dashboard", "Organisations"],
        },
      },
      {
        path: "/dashboard/users",
        name: "Users",

        component: UsersList,
        meta: {
          name: "Users",
          breadcrumbs: ["Dashboard", "Users"],
        },
      },
      {
        path: "/dashboard/users/new",
        name: "Add_a_new_User",

        component: NewUser,
        meta: {
          name: "Add a new User",
          breadcrumbs: ["Dashboard", "Users", "Add_a_new_User"],
        },
      },
      {
        path: "/dashboard/users/:id",
        name: "User_Profile",

        component: UserProfile,
        meta: {
          name: "User Profile",
          breadcrumbs: ["Dashboard", "Users", "User_Profile"],
        },
      },
      {
        path: "/dashboard/user-groups",
        name: "User_Groups",

        component: UserGroups,
        meta: {
          name: "User Groups",
          breadcrumbs: ["Dashboard", "User_Groups"],
        },
      },
      {
        path: "/dashboard/user-groups/:role",
        name: "User_Group",

        component: UserGroup,
        meta: {
          name: "User Group",
          breadcrumbs: ["Dashboard", "User_Groups", "User_Group"],
        },
      },
      {
        path: "/dashboard/orders",
        name: "Orders",
        component: OrdersList,
        meta: {
          name: "Orders",
          breadcrumbs: ["Dashboard", "Orders"],
        },
      },
      {
        path: "/dashboard/orders/licenses/:oid/:lid",
        name: "License",
        component: LicenseView,
        meta: {
          name: "License",
          breadcrumbs: ["Dashboard", "Orders", "License"],
        },
      },
      {
        path: "/dashboard/orders/subscriptions/:id",
        name: "SubscriptionOrder",
        component: SubscriptionOrderView,
        meta: {
          name: "Subscription",
          breadcrumbs: ["Dashboard", "Orders", "Subscription"],
        },
      },
      {
        path: "/dashboard/courses",
        name: "Courses",
        component: TemplatesIndex,
        meta: {
          name: "Courses",
          breadcrumbs: ["Dashboard", "Courses"],
        },
      },
      {
        path: "/dashboard/courses/certificates",
        name: "Certificates",

        component: CertificatesList,
        meta: {
          name: "Courses",
          breadcrumbs: ["Dashboard", "Courses", "Certificates"],
        },
      },
      {
        path: "/dashboard/actions/:id",
        name: "Course_Prerequisites",

        component: AcceptTerms,
        meta: {
          name: "Course Prerequisites",
          breadcrumbs: ["Dashboard", "Course_Prerequisites"],
        },
      },
      {
        path: "/dashboard/courses/templates",
        name: "Course_Templates",

        component: TemplatesList,
        meta: {
          name: "Course Templates",
          breadcrumbs: ["Dashboard", "Courses", "Course_Templates"],
        },
      },
      {
        path: "/dashboard/courses/templates/new",
        name: "Add_a_new_Template",

        component: NewTemplate,
        meta: {
          name: "Add a new Template",
          breadcrumbs: ["Dashboard", "Courses", "Course_Templates", "Add_a_new_Template"],
        },
      },
      {
        path: "/dashboard/courses/templates/:id",
        name: "Course_Template",
        component: TemplatePage,
        meta: {
          name: "Course Template",
          breadcrumbs: ["Dashboard", "Courses", "Course_Templates", "Course_Template"],
        },
      },
      {
        path: "/dashboard/courses/templates/:id/edit",
        name: "Edit_Course_Template",
        component: EditTemplatePage,
        meta: {
          name: "Edit Course Template",
          breadcrumbs: ["Dashboard", "Courses", "Course_Templates", "Course_Template", "Edit_Course_Template"],
        },
      },
      {
        path: "/dashboard/courses/events",
        name: "Training_Events",

        component: EventsList,
        meta: {
          name: "Training Events",
          breadcrumbs: ["Dashboard", "Courses", "Training_Events"],
        },
      },
      {
        path: "/dashboard/courses/events/new",
        name: "Add_a_new_Event",

        component: NewEvent,
        meta: {
          name: "Add a new Event",
          breadcrumbs: ["Dashboard", "Courses", "Training_Events", "Add_a_new_Event"],
        },
      },
      {
        path: "/dashboard/courses/events/:id",
        name: "Training_Event",

        component: EventPage,
        meta: {
          name: "Training Event",
          breadcrumbs: ["Dashboard", "Courses", "Training_Events", "Training_Event"],
        },
      },
      {
        path: "/dashboard/courses/events/:id/edit",
        name: "Edit_Training_Event",

        component: EditEventPage,
        meta: {
          name: "Edit Training Event",
          breadcrumbs: ["Dashboard", "Courses", "Training_Events", "Training_Event", "Edit_Training_Event"],
        },
      },
      {
        path: "/dashboard/courses/events/:id/bookings/:bookingId",
        name: "User_Order",

        component: OrderPage,
        meta: {
          name: "User Order",
          breadcrumbs: ["Dashboard", "Courses", "Training_Events", "Training_Event", "User_Order"],
        },
      },
      {
        path: "/dashboard/courses/discounts",
        name: "Discounts",
        component: DiscountsList,
        meta: {
          name: "Discounts",
          breadcrumbs: ["Dashboard", "Courses", "Discounts"],
        },
      },
      {
        path: "/dashboard/courses/discounts/new",
        name: "add_a_new_discount",
        component: NewDiscount,
        meta: {
          name: "Add a New Discount",
          breadcrumbs: ["Dashboard", "Courses", "Discounts", "add_a_new_discount"],
        },
      },
      {
        path: "/dashboard/courses/discounts/:id",
        name: "Discount",
        component: DiscountPage,
        meta: {
          name: "Discount",
          breadcrumbs: ["Dashboard", "Courses", "Discounts", "Discount"],
        },
      },
      {
        path: "/dashboard/courses/discounts/:id/edit",
        name: "Edit_Discount",
        component: EditDiscount,
        meta: {
          name: "Edit Discount",
          breadcrumbs: ["Dashboard", "Courses", "Discounts", "Discount", "Edit_Discount"],
        },
      },
      {
        path: "/dashboard/children",
        name: "My_Children",
        component: ClubMembers,
        meta: {
          name: "My_Children",
          breadcrumbs: ["Dashboard", "My_Children"],
        },
      },
      {
        path: "/dashboard/children/:student_id",
        name: "View_Child",
        component: StudentPage,
        meta: {
          name: "View_Child",
          breadcrumbs: ["Dashboard", "My_Children", "View_Child"],
        },
      },
      {
        path: "/dashboard/children/:student_id/edit",
        name: "Edit_Child",
        component: EditStudent,
        meta: {
          name: "Edit_Child",
          breadcrumbs: ["Dashboard", "My_Children", "View_Child", "Edit_Child"],
        },
      },
      {
        path: "/dashboard/clubs/members/:student_id",
        name: "View_Member",
        component: StudentPage,
        meta: {
          name: "View Member",
          breadcrumbs: ["Dashboard", "Brick_Club_Members", "View_Member"],
        },
      },
      {
        path: "/dashboard/clubs/members/:student_id/edit",
        name: "Edit_Member",
        component: EditStudent,
        meta: {
          name: "Edit Member",
          breadcrumbs: ["Dashboard", "Brick_Club_Members", "View_Member", "Edit_Member"],
        },
      },
      {
        path: "/dashboard/clubs",
        name: "Brick_Clubs",
        component: ClubsList,
        meta: {
          name: "Brick Clubs",
          breadcrumbs: ["Dashboard", "Brick_Clubs"],
        },
      },
      {
        path: "/dashboard/clubs/new",
        name: "Add_Brick_Club",
        component: NewClubVue,
        meta: {
          name: "Add Brick Club",
          breadcrumbs: ["Dashboard", "Brick_Clubs", "Add_Brick_Club"],
        },
      },
      {
        path: "/dashboard/clubs/:id",
        name: "Brick_Club",
        component: ClubPageVue,
        meta: {
          name: "Brick Club",
          breadcrumbs: ["Dashboard", "Brick_Clubs", "Brick_Club"],
        },
      },
      {
        path: "/dashboard/clubs/:id/edit",
        name: "Edit_Brick_Club",
        component: EditClubPageVue,
        meta: {
          name: "Edit Brick Club",
          breadcrumbs: ["Dashboard", "Brick_Clubs", "Brick_Club", "Edit_Brick_Club"],
        },
      },
      {
        path: "/dashboard/clubs/:id/participants/new",
        name: "Add_Club_Participant",
        component: NewStudent,
        meta: {
          name: "Add Club Participant",
          breadcrumbs: ["Dashboard", "Brick_Clubs", "Brick_Club", "Add_Club_Participant"],
        },
      },
      {
        path: "/dashboard/clubs/:id/participants/:student_id",
        name: "View_Club_Participant",
        component: StudentPage,
        meta: {
          name: "View Club Participant",
          breadcrumbs: ["Dashboard", "Brick_Clubs", "Brick_Club", "View_Club_Participant"],
        },
      },
      {
        path: "/dashboard/clubs/:id/participants/:student_id/edit",
        name: "Edit_Club_Participant",
        component: EditStudent,
        meta: {
          name: "Edit Club Participant",
          breadcrumbs: ["Dashboard", "Brick_Clubs", "Brick_Club", "View_Club_Participant", "Edit_Club_Participant"],
        },
      },
      {
        path: "/dashboard/training-feedback",
        name: "Training_Feedback",
        component: SurveyIndex,
        meta: {
          name: "Training Feedback",
          breadcrumbs: ["Dashboard", "Training_Feedback"],
        },
      },
      {
        path: "/dashboard/training-feedback/:id",
        name: "Survey",
        component: SurveyPage,
        meta: {
          name: "Survey",
          breadcrumbs: ["Dashboard", "Training_Feedback", "Survey"],
        },
      },
      {
        path: "/dashboard/member-outcomes",
        name: "Member_Outcomes",
        component: SurveyIndex,
        meta: {
          name: "Member Outcomes",
          breadcrumbs: ["Dashboard", "Member_Outcomes"],
        },
      },
      {
        path: "/dashboard/member-outcomes/:id",
        name: "Club_Feedback",
        component: ClubFeedbackPage,
        meta: {
          name: "Club Feedback",
          breadcrumbs: ["Dashboard", "Member_Outcomes", "Club_Feedback"],
        },
      },
      {
        path: "/dashboard/child-outcomes",
        name: "Child_Outcomes",
        component: ChildOutcomes,
        meta: {
          name: "Child Outcomes",
          breadcrumbs: ["Dashboard", "Child_Outcomes"],
        },
      },
      {
        path: "/dashboard/resources",
        name: "Resources",
        component: ResourcesList,
        meta: {
          name: "Resources",
          breadcrumbs: ["Dashboard", "Resources"],
        },
      },
      {
        path: "/dashboard/resources/new",
        name: "Upload_Resource",
        component: NewResource,
        meta: {
          name: "Upload Resources",
          breadcrumbs: ["Dashboard", "Upload_Resource"],
        },
      },
      {
        path: "/dashboard/resources/:id",
        name: "Resource",
        component: ResourcePage,
        meta: {
          name: "Resource",
          breadcrumbs: ["Dashboard", "Resource"],
        },
      },
      {
        path: "/dashboard/resources/:id/edit",
        name: "Edit_Resource",
        component: EditResource,
        meta: {
          name: "Edit Resource",
          breadcrumbs: ["Dashboard", "Resource", "Edit_Resource"],
        },
      },
      {
        path: "/logout",
        name: "Logout",
        component: LogoutView,
        meta: {
          name: "Logout",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(to => {
  const { isUserLoading, isUserSubscribed } = storeToRefs(useUserStore());

  if (!isUserLoading && to.fullPath.includes("/dashboard") && to.name !== "Dashboard" && !isUserSubscribed.value) {
    console.log("user not subbed");
    return { name: "Dashboard" };
  }

  document.title = `${to.meta.name} | Play Included Admin`;
});

export default router;

import { Meta, TagRequest, Tag } from "@/types";
import { axiosInstance } from "../clients";

async function listTags(): Promise<{ data: { tags: Tag[] } }> {
  return (await axiosInstance.get(`/v1/tags`, { withCredentials: true })).data;
}

async function getTag(id: string): Promise<{ data: { tag: Tag }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/tags/${id}`, { withCredentials: true })).data;
}

async function createTag(data: TagRequest): Promise<{ data: { tag: Tag }; meta: Meta }> {
  return (await axiosInstance.post("/v1/tags", data, { withCredentials: true })).data;
}

async function updateTag(id: string, data: any): Promise<{ data: { tag: Tag }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/tags/${id}`, data, { withCredentials: true })).data;
}

async function deleteTag(id: string): Promise<{ data: { deleted: boolean }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/tags/${id}`, { withCredentials: true })).data;
}

export default {
  listTags,
  getTag,
  createTag,
  updateTag,
  deleteTag,
};

export default {
  "new_evnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Event (spanish)"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website (spanish)"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community (spanish)"])},
  "email_marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Marketing (spanish)"])},
  "email_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Preferences (spanish)"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard (spanish)"])},
  "my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile (spanish)"])},
  "account_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Security (spanish)"])},
  "add_a_new_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new User (spanish)"])},
  "user_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Profile (spanish)"])},
  "user_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Groups (spanish)"])},
  "user_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group (spanish)"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses (spanish)"])},
  "new_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new event (spanish)"])},
  "course_prerequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Prerequisites (spanish)"])},
  "course_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Templates (spanish)"])},
  "add_a_new_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Template (spanish)"])},
  "edit_course_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Course Template (spanish)"])},
  "training_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Events (spanish)"])},
  "add_a_new_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Event (spanish)"])},
  "training_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Event (spanish)"])},
  "user_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Order (spanish)"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout (spanish)"])},
  "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources (spanish)"])},
  "upload_Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Resource (spanish)"])},
  "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource (spanish)"])},
  "edit_Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Resource (spanish)"])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts (spanish)"])},
  "add_a_new_Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Discount (spanish)"])},
  "edit_Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Discount (spanish)"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount (spanish)"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates (spanish)"])},
  "brick_clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Clubs (spanish)"])},
  "view_club_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view club participant (spanish)"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["summary (spanish)"])},
  "course_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course description (spanish)"])},
  "registered_attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registered attendees (spanish)"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing (spanish)"])},
  "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individual (spanish)"])},
  "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course (spanish)"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all (spanish)"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelled (spanish)"])},
  "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheduled (spanish)"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draft (spanish)"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completed (spanish)"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmed (spanish)"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["published (spanish)"])},
  "no_registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no registrations (spanish)"])},
  "minimum_not_met": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum not met (spanish)"])},
  "in review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in review (spanish)"])},
  "unpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unpublished (spanish)"])},
  "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event name (spanish)"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actions (spanish)"])},
  "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["training (spanish)"])},
  "trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trainer (spanish)"])},
  "course_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course template (spanish)"])},
  "course_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course level (spanish)"])},
  "course_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course type (spanish)"])},
  "delivery_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery method (spanish)"])},
  "delivery_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery date (spanish)"])},
  "available_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available countries (spanish)"])},
  "date_and_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date and time (spanish)"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location (spanish)"])},
  "training_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["training link (spanish)"])},
  "event_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event owner (spanish)"])},
  "presenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["presenters (spanish)"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price (spanish)"])},
  "payment_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment link (spanish)"])},
  "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["platform name for payments (spanish)"])},
  "attendance_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attendace limit (spanish)"])},
  "maximum_attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maximum number of participants (spanish)"])},
  "minimum_attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum number of participants (spanish)"])},
  "waiting_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waiting list (spanish)"])},
  "go_live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date to publish this event (spanish)"])},
  "registration_cut_off_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration cut off date (spanish)"])},
  "start_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start time (spanish)"])},
  "end_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end time (spanish)"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["region (spanish)"])},
  "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation (spanish)"])},
  "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment method (spanish)"])},
  "payment_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment platform (spanish)"])},
  "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid (spanish)"])},
  "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complete (spanish)"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view (spanish)"])},
  "thank_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you, your course has been submitted for review. You will be sent an invoice for the course licence fee within the next 5 working days. Your course will be approved by the Play Included team once the licence fee payment has been received. (spanish)"])},
  "status_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This event is (spanish)"])},
  "course_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course feedback (spanish)"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rating (spanish)"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedback (spanish)"])},
  "welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back! (spanish)"])},
  "your_training_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Training Events (spanish)"])},
  "submit_event_for_approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Event for Approval (spanish)"])},
  "edit_training_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Training Event (spanish)"])},
  "save_training_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Training Event (spanish)"])},
  "confirm_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Event (spanish)"])},
  "publish_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Event Now (spanish)"])},
  "unpublish_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpublish Event (spanish)"])},
  "cancel_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Event (spanish)"])},
  "change_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Price (spanish)"])},
  "add_a_presenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a presenter (spanish)"])},
  "add_another_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another date (spanish)"])},
  "select_available_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Available Countries (spanish)"])},
  "make_training_event_go_live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Training Event for Live (spanish)"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete (spanish)"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove (spanish)"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel (spanish)"])},
  "save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes (spanish)"])},
  "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete account (spanish)"])},
  "change_your_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change your password (spanish)"])},
  "add_session_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Session Note (spanish)"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save (spanish)"])},
  "add_existing_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add existing participant (spanish)"])},
  "save_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save participant (spanish)"])},
  "edit_brick_club_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit brick club participant (spanish)"])},
  "delete_brick_club_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete brick club participant (spanish)"])},
  "add_new_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add new participant (spanish)"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name (spanish)"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name (spanish)"])},
  "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email address (spanish)"])},
  "email_address_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing this will require you to login again (spanish)"])},
  "your_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your roles (spanish)"])},
  "your_roles_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter each job on a new line (spanish)"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bio (spanish)"])},
  "facilitator_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facilitator settings (spanish)"])},
  "trainer_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trainer settings (spanish)"])},
  "hide_facilitator_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hide facilitator profile (spanish)"])},
  "facilitator_public_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This profile will be viewable on the website as a facilitator. If you do not want this to appear on the website please toggle this setting off (spanish)"])},
  "works_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with (spanish)"])},
  "hide_trainer_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hide trainer profile (spanish)"])},
  "trainer_public_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This profile will be viewable on the website as a trainer. If you do not want this to appear on the website please toggle this setting off (spanish)"])},
  "language_and_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language and region (spanish)"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city (spanish)"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language (spanish)"])},
  "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timezone (spanish)"])},
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code (spanish)"])},
  "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promotion (spanish)"])},
  "starts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starts (spanish)"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expires (spanish)"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country (spanish)"])},
  "limit_per_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limit per customer (spanish)"])},
  "limit_total_uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limit total uses (spanish)"])},
  "starts_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start at (spanish)"])},
  "expires_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expires at (spanish)"])},
  "specific_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specific user (spanish)"])},
  "specific_organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specific organisation (spanish)"])},
  "apply_to_courses_tagged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apply to courses tagged (spanish)"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name (spanish)"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date (spanish)"])},
  "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["venue (spanish)"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setting (spanish)"])},
  "first_club_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first club date (spanish)"])},
  "last_club_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last club date (spanish)"])},
  "participants_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of participants (spanish)"])},
  "places_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["places available (spanish)"])},
  "session_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session notes (spanish)"])},
  "club_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["club participants (spanish)"])},
  "age_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["age range (spanish)"])},
  "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session (spanish)"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["note (spanish)"])},
  "initials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["initials (spanish)"])},
  "background_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["background notes (spanish)"])},
  "brick_club_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brick club notes (spanish)"])},
  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created (spanish)"])},
  "course_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger at the end of (spanish)"])},
  "last_modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last modified (spanish)"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status (spanish)"])},
  "facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitator (spanish)"])},
  "date_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Completed (spanish)"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users (spanish)"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created (spanish)"])},
  "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updated (spanish)"])},
  "training_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["training feedback (spanish)"])},
  "submit_for_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submit for review"])}
}
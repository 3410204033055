import { defineStore } from "pinia";
import api from "@/api/api";
import router from "@/router";
import { User } from "@/types";
import { userHasRole } from "@/utils/roles";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: {} as User,
      isUserLoading: true,
      isUserSubscribed: false,
      timezone: "",
      short_timezone: "",
      timezone_offset: 0,
      adminRoles: ["admin", "courses-admin"],
    };
  },
  getters: {
    getUser: state => state.user,
    userIsAdmin: state => state.adminRoles.some(role => state.user.roles.some(userRole => userRole.name === role)),
  },
  actions: {
    async fetchUser() {
      try {
        this.isUserLoading = true;
        const {
          data: { user },
        } = await api.auth.verifyJWT();

        this.user = user;
        this.timezone = user.timezone ? user.timezone : "Europe/London";

        if(userHasRole(this.user.roles, ["admin", "course_admin", "clubs_admin", "users_admin"])) {
          this.isUserSubscribed = true;
        }

        if (this.user.subscription_status === "active") {
          this.isUserSubscribed = true;
        }

        /** Set Timezone */
        const date = new Date();
        const timezoneAbbrv = new Intl.DateTimeFormat("en-GB", { dateStyle: "short", timeStyle: "long", timeZone: this.timezone }).format(date);

        this.short_timezone = timezoneAbbrv.split(" ")[2];
        this.timezone_offset = date.getTimezoneOffset();
      } catch (e) {
        const currentRoutePath = router.currentRoute.value.path;

        const noRedirectRoutes = ["/signup", "/signup/redirect", "/redirect"];
        if (noRedirectRoutes.includes(currentRoutePath)) {
          return;
        }

        // const redirectRoutes = ["/"];
        // if (redirectRoutes.includes(currentRoutePath)) {
        //   return router.push("/");
        // }
      } finally {
        this.isUserLoading = false;
      }
    },
  },
});

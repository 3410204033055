import { Meta, PaginationMeta } from "@/types";
import { axiosInstance } from "../clients";
import { Organisation } from "@/types/User";

async function listOrganisations(
  page?: string,
  search?: string,
  region?: string,
  sort?: string,
  dir?: string
): Promise<{ meta?: PaginationMeta | Meta; data: { organisations: Organisation[] } }> {
  const queryParams: { page?: string; search?: string; region?: string; sort?: string; dir?: string } = {};

  if (page) queryParams["page"] = page;
  if (search) queryParams["search"] = search;
  if (sort) queryParams["sort"] = sort;
  if (dir) queryParams["dir"] = dir;
  if (region) queryParams["region"] = region;

  return (await axiosInstance.get(`/v1/organisations`, { withCredentials: true, params: queryParams })).data;
}

async function toggleTrainerFee(organisation_id: Organisation["id"]): Promise<{ data: { organisation: Organisation } }> {
  return (await axiosInstance.post(`/v1/organisations/${organisation_id}/payment`, { withCredentials: true })).data;
}

export default { listOrganisations, toggleTrainerFee };

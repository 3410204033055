import { Meta, Level, Template, CourseType, PaginationMeta } from "@/types";
import { axiosInstance } from "../clients";

async function listTemplates(page: string, type?: string): Promise<{ data: { templates: Template[] }; meta: PaginationMeta }> {
  const queryParams: { page: string; type?: string } = {
    page,
  };

  if (type) queryParams["type"] = type;

  return (await axiosInstance.get(`/v1/templates`, { withCredentials: true, params: queryParams })).data;
}

async function listTemplateTypes(): Promise<{ data: { types: CourseType[] } }> {
  return (await axiosInstance.get(`/v1/public/templates/types`, { withCredentials: true })).data;
}

async function listTemplateLevels(): Promise<{ data: { levels: Level[] } }> {
  return (await axiosInstance.get(`/v1/public/templates/levels`, { withCredentials: true })).data;
}

async function getTemplate(id: number, options: { events: Boolean }): Promise<{ data: { template: Template; story: { [key: string]: any } }; meta: Meta }> {
  if (options.events) {
    return (await axiosInstance.get(`/v1/templates/${id}?events=true`, { withCredentials: true })).data;
  }

  return (await axiosInstance.get(`/v1/templates/${id}`, { withCredentials: true })).data;
}

async function getTemplateStory(id: number): Promise<{ data: { story: any } }> {
  return (await axiosInstance.get(`/v1/public/templates/${id}/story`, { withCredentials: true })).data;
}

async function createTemplate(data: any): Promise<{ data: { template: Template }; meta: Meta }> {
  return (await axiosInstance.post("/v1/templates", data, { withCredentials: true })).data;
}

async function updateTemplate(id: number, data: any): Promise<{ data: { template: Template }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/templates/${id}`, data, { withCredentials: true })).data;
}

async function updateStatus(id: number, data: { status: string }): Promise<{ data: { template: Template }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/templates/${id}/status`, data, { withCredentials: true })).data;
}

async function deleteTemplate(id: number): Promise<{ data: { deleted: boolean }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/templates/${id}`, { withCredentials: true })).data;
}

export default {
  listTemplates,
  listTemplateTypes,
  listTemplateLevels,
  getTemplate,
  getTemplateStory,
  createTemplate,
  updateTemplate,
  updateStatus,
  deleteTemplate,
};

import api from "@/api/api";
import { Country, CourseType, Language, Role } from "@/types";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

export const useGlobalStore = defineStore("global", {
  state: () => {
    return {
      isMobileMenuOpen: false,
      countries: [] as Country[],
      course_types: [] as CourseType[],
      user_roles: [] as Role[],
      course_roles: [] as Role[],
      currentLanguage: {} as Language,
      languages: [] as Language[],
      timezones: [] as any[],
    };
  },
  actions: {
    async toast(type: "SUCCESS" | "ERROR" | "WARNING" | "INFO", content: string) {
      const toast = useToast();

      return toast(content, {
        type: TYPE[type],
      });
    },
    async fetchCountries() {
      try {
        const response = await api.misc.listCountries();
        this.countries = response.countries;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async fetchCourseTypes() {
      try {
        const response = await api.templates.listTemplateTypes();
        this.course_types = response.data.types;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async fetchUserRoles() {
      try {
        const response = await api.users.listRoles();
        this.user_roles = response.data.roles;
        this.course_roles = this.user_roles.filter(i => !i.name.includes("admin"));
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async fetchLanguages() {
      this.languages = (await api.misc.listServicePortalLanguages()).languages;

      // @ts-ignore
      this.timezones = Intl.supportedValuesOf("timeZone");
    },
    /** Returns a formatted Display Name of given role. i.e l4a-trainer -> Level 4a Trainer */
    getFullRoleDisplayName(roleName: string) {
      if (roleName === "admin") {
        return "Admin";
      }

      const role = this.course_roles.find(i => i.name === roleName);

      return role!.display_name;
    },
    /** Returns the lowest Role by order in an array of Roles */
    getLowestRole(roles: string[]) {
      const dbRoles = roles.map(role => {
        return this.user_roles.find(i => i.name === role);
      });

      // @ts-ignore
      const lowestRole = dbRoles.reduce((prev, current) => (prev.order < current.order ? prev : current))?.display_name;

      return lowestRole;
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
  },
});

enum TYPE {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  DEFAULT = "default",
}

import { createApp } from "vue";
import { createPinia } from "pinia";
import "vue-toastification/dist/index.css";
import Toast, { PluginOptions, POSITION } from "vue-toastification";

import App from "./App.vue";
import router from "./router";
import Datepicker from "@vuepic/vue-datepicker";

import "./assets/datepicker.css";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

/** Locales */
import i18n from "./i18n";

const vue = createApp(App).use(i18n);

const options: PluginOptions = {
  position: POSITION.TOP_RIGHT,
  timeout: 2000,
  newestOnTop: true,
};

vue.use(router);
vue.use(createPinia());
vue.use(Toast, options);
vue.component("DatePicker", Datepicker);
vue.component("QuillEditor", QuillEditor);

vue.mount("#app");

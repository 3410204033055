import { PaginationMeta, Meta, Club, ClubDate, ClubNote, Student, CreateStudentRequest, Attendance, MarkStudentAttendanceRequest, User } from "@/types";
import { axiosInstance } from "../clients";
import { CreateClubNoteRequest } from "@/types/Club";
import { EditStudentRequest } from "@/types/Student";

async function listClubs(data: {
  page: number | string;
  status?: string;
  location?: string;
  search?: string;
}): Promise<{ data: { clubs: Club[]; countries: string[]; facilitators: User[] }; meta: PaginationMeta }> {
  return (await axiosInstance.get(`/v1/clubs`, { withCredentials: true, params: data })).data;
}

async function listUserClubs(page: string, id: string): Promise<{ data: { clubs: Club[] }; meta: PaginationMeta }> {
  const queryParams = `page=${page}&facilitator_id=${id}`;
  return (await axiosInstance.get(`/v1/clubs?${queryParams}`, { withCredentials: true })).data;
}

async function getClub(id: Club["id"]): Promise<{ data: { club: Club }; meta: Meta }> {
  return (await axiosInstance.get(`/v1/clubs/${id}`, { withCredentials: true })).data;
}

async function createClub(data: any): Promise<{ data: { club: Club; dates: Omit<ClubDate, "club">[] }; meta: Meta }> {
  return (await axiosInstance.post("/v1/clubs", data, { withCredentials: true })).data;
}

async function updateClub(id: Club["id"], data: any): Promise<{ data: { club: Club }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/clubs/${id}`, data, { withCredentials: true })).data;
}

async function updateClubVisibility(id: Club["id"], data: { status: string }): Promise<{ data: { club: Club }; meta: Meta }> {
  return (await axiosInstance.post(`/v1/clubs/${id}/visibility`, data, { withCredentials: true })).data;
}

async function deleteClub(id: Club["id"]): Promise<{ data: { deleted: boolean }; meta: Meta }> {
  return (await axiosInstance.delete(`/v1/clubs/${id}`, { withCredentials: true })).data;
}

/** Club Notes */
async function listClubNotes(club_id: Club["id"]) {
  return (await axiosInstance.get(`/v1/clubs/${club_id}/notes`, { withCredentials: true })).data;
}

async function createClubNote(club_id: Club["id"], data: CreateClubNoteRequest) {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/notes`, data, { withCredentials: true })).data;
}

async function updateClubNote(club_id: Club["id"], id: ClubNote["id"], data: CreateClubNoteRequest) {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/notes/${id}`, data, { withCredentials: true })).data;
}

async function deleteClubNote(club_id: Club["id"], id: ClubNote["id"]) {
  return (await axiosInstance.delete(`/v1/clubs/${club_id}/notes/${id}`, { withCredentials: true })).data;
}

/** Club Students */
async function listClubStudents(page: string | number, club_id: Club["id"], search?: string): Promise<{ data: { students: Student[] }; meta: PaginationMeta }> {
  const queryParams: { page: number | string; search?: string } = {
    page,
  };

  if (search) queryParams["search"] = search;

  return (await axiosInstance.get(`/v1/clubs/${club_id}/students`, { withCredentials: true, params: queryParams })).data;
}

async function getClubStudent(club_id: Club["id"], student_id: Student["id"]): Promise<{ data: { student: Student } }> {
  return (await axiosInstance.get(`/v1/clubs/${club_id}/students/${student_id}`, { withCredentials: true })).data;
}

async function createClubStudent(club_id: Club["id"], data: CreateStudentRequest): Promise<{ data: { student: Student } }> {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/students`, data, { withCredentials: true })).data;
}

async function updateClubStudent(club_id: Club["id"], student_id: Student["id"], data: EditStudentRequest): Promise<{ data: { student: Student } }> {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/students/${student_id}`, data, { withCredentials: true })).data;
}

async function addStudentToClub(club_id: Club["id"], student_id: Student["id"]): Promise<{ data: { student: Student } }> {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/students/${student_id}/add`, {}, { withCredentials: true })).data;
}

async function removeStudentFromClub(club_id: Club["id"], student_id: Student["id"]): Promise<{ data: { student: Student } }> {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/students/${student_id}/remove`, {}, { withCredentials: true })).data;
}

/** Attendance */
async function listClubAttendance(club_id: Club["id"]): Promise<{ data: { attendance: Attendance[] } }> {
  return (await axiosInstance.get(`/v1/clubs/${club_id}/attendance`, { withCredentials: true })).data;
}

async function markStudentAttendance(club_id: Club["id"], data: MarkStudentAttendanceRequest): Promise<{ data: { attendance: Attendance } }> {
  return (await axiosInstance.post(`/v1/clubs/${club_id}/attendance`, data, { withCredentials: true })).data;
}

export default {
  listClubs,
  listUserClubs,
  getClub,
  createClub,
  updateClub,
  updateClubVisibility,
  deleteClub,

  listClubNotes,
  createClubNote,
  updateClubNote,
  deleteClubNote,

  listClubStudents,
  getClubStudent,
  createClubStudent,
  updateClubStudent,
  addStudentToClub,
  removeStudentFromClub,

  listClubAttendance,
  markStudentAttendance,
};
